<template>
<div>
  <TopMenuSubheadline :title="title"/>
 <!-- <div v-if="isLoading">
    <Loader />
  </div> -->
  <div class="app-content-box bg-gray">
    <div class="container wrapper-showmore">
      <div class="books-list-masonry">
        <div v-for="(res, key) in list" :key="key">
         <div>
          <router-link :to="{name: 'BooksDetails', params: {id: res.id}}">
            <div class="book-card">
              <div class="book-cover-container">
                <div class="book-cover"
                v-for="(item, id) in 1" :key="id">
                  <img
                    :src="res.image ? (coverUrl + res.image) : (coverUrl + 'no_cover.png')"
                  />
                </div>
                <div class="bottom-plus-sign" @click.prevent="addBook(res.id, res)"><i class="fas fa-plus-circle"></i></div>
              </div>
              <div class="mb-3">
                <a href="" class="book-title">{{ res.autor }}</a>
                <span class="book-info">{{ res.tytul }}</span>
                <div class="stars-wrapper mt-1" v-if="res.rating > 0">
                  <star-rating :increment="0.5" active-color="#000" :star-size="starSize"
                  inactive-color="#F1F1F1" active-border-color="#000" border-color="#000000"
                  :border-width='borderWidth' :show-rating="false" :read-only="true"
                  :rounded-corners='rounded' :rating="res.rating" >
                  </star-rating>
                </div>
                <div class="rating-size center mt-1 text-dark" v-if="res.rating > 0">Ocena: {{parseFloat(res.rating, 10)}}</div>
              </div>
          </div>
          </router-link>
         </div>
        </div>
      </div>
      <infinite-loading @infinite="handleAllBooks"></infinite-loading>
    </div>
  </div>
 <ModalSlot title="Dodaj książkę" v-if="isPopupShown" @close-modal="closeModal">
      <div class="text-center mb-2 modal-main-title">Dodaj książkę</div>
      <div class="modal-wrapper">
        <div class="growing-slide-cover wrapper-cover w-100">
          <img :src="( cover ? coverUrl + cover : coverUrl + 'no_cover.png')" class="slide-cover-image"/>
          </div>
          <div class="book-heading-big text-center mt-3">{{author}}</div>
          <div class="book-heading-small text-center mt-2">{{title}}</div>
      </div>
      <router-link :to="{name: 'BorrowBook', params: {id: book_id}}"><button class="btn-black-border w-100 mt-3 mb-1">Dodaj na półkę</button></router-link>
      <button class="btn-black-border w-100 mt-3 mb-1" @click="saveToLater">Zapisz na później</button>
       <button class="btn-black-full w-100 mt-3 mb-1" @click="closeModal(false)">Zamknij</button>
    </ModalSlot>
</div>
</template>
<script>
import ModalSlot from '../components/ModalSlot'
// import Loader from '../components/Loader'
import TopMenuSubheadline from '../components/TopMenuSubheadline'
import StarRating from 'vue-star-rating'
export default {
  name: 'ShelfShowMore',
  props: {
    id: [String]
  },
  components: {
    TopMenuSubheadline,
    ModalSlot,
    StarRating
    // Loader
  },
  data () {
    return {
      page: 1,
      list: [],
      allBooks: [],
      isPopupShown: false,
      book_id: '',
      cover: '',
      author: '',
      title: '',
      isLoading: true,
      starsReadOnly: true,
      rating: 1,
      borderWidth: 2,
      animate: true,
      rounded: true,
      starSize: 18
    }
  },
  methods: {
    handleAllBooks ($s) {
      this.$https(`/shelf-books/${this.id}?page=${this.page}`, {
        method: 'GET',
        mode: 'no-cors',
        crossDomain: true,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      })
        .then(({ data }) => {
          this.isLoading = false
          this.allBooks = data.response[0].items
          if (data.response[0].id !== null) {
            this.title = 'Wszystkie z półki: ' + data.response[0].label
          } else {
            this.title = 'Ta półka nie istnieje'
          }
          this.subtitle = data.response[0].label
          if (this.allBooks.length) {
            this.page += 1
            this.allBooks.forEach(e => {
              this.list.push(e)
            })
            $s.loaded()
          } else {
            $s.complete()
          }
        })
        .catch(error => {
          console.log('', error)
        })
    },
    addBook (id, book) {
      this.book_id = id
      this.cover = book.image
      this.author = book.autor
      this.title = book.tytul
      this.isPopupShown = true
    },
    closeModal () {
      this.isPopupShown = false
    },
    saveToLater () {
      const getData = 'status=later&own=1&borrowed=0'
      this.$https('book/' + this.book_id + '/add/' + 0, {
        method: 'POST',
        mode: 'no-cors',
        crossDomain: true,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        data: getData
      }).then(response => {
        this.closeModal()
      })
        .catch(error => {
          console.log('', error)
        })
    }
  }
}
</script>
<style lang="scss" scoped>
  .app-content-box {
    min-height: 100vh;
  }
  .wrapper-showmore {
    padding-top: 50px;
    padding-bottom: 40px;
  }
  .wrapper-cover {
    display: flex;
    justify-content: center;

    img {
      max-height: 270px;
      max-width: 210px;
    }
  }
  .rating-size {
    font-size: 14px;
  }
</style>
